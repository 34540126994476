import React, { useState, useEffect , useCallback} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as styles from "../../components/style/product_nutrients.module.scss"

import OkNutrient from '../../images/svg/good_overlapping.inline.svg'
//import BadNutrient from '../../images/svg/issue_overlapping.inline.svg'
import BadNutrient from '../../images/svg/arrow_overlapping.inline.svg'

function ProductNutrients(props) {
  	const [productOverlapping, setProductOverlapping] = useState([]);

    const checkOverlapping = useCallback(() => {
        var global_nutrients = {};
        props.products.forEach((item, i_index) => {
            if(item.nutrients && item.nutrients.length) {
                item.nutrients.forEach((_nutrient) => {
                    if(_nutrient.nutrient !== undefined && _nutrient.nutrient.code) {
                        if(global_nutrients[_nutrient.nutrient.code] === undefined) {
                            _nutrient.nutrient.total_found = 0;
                            _nutrient.nutrient.total_products = 0;
                            global_nutrients[_nutrient.nutrient.code] = _nutrient.nutrient;
                        }
                        if(!isNaN(+_nutrient.amount)) {
                            global_nutrients[_nutrient.nutrient.code].total_products = global_nutrients[_nutrient.nutrient.code].total_products + 1;
                            global_nutrients[_nutrient.nutrient.code].total_found = global_nutrients[_nutrient.nutrient.code].total_found + (+_nutrient.amount);
                        }
                    }
                });
            }
        });

        const global_nutrients_sorted = [...Object.values(global_nutrients)].sort((a, b) => a.order - b.order);
        setProductOverlapping(global_nutrients_sorted);
    }, [props]);

    const isOverLimit = (nutrient) => {
        if(!nutrient.limit_calculation) {
            return false;
        }

        if(nutrient.total_found > nutrient.limit_calculation) {
            return true;
        }

        return false
    }

    useEffect(() => { checkOverlapping() }, [props.products , checkOverlapping])

    return (
        <>
        { props.products.length > 0 &&
            <>
            {props.products.length > 1 &&
                <>
                    <div className="mb-4 pt-3 text-center">
                        <h2 className="uppercase mb-3"><b>OVERLAPPING NUTRIENTS</b></h2>
                        <p>These ingredients are found in more than one of the products you selected.</p>
                        <p>If any nutrient is over your desired amount, deselect a product above to remove it from your routine and click "continue".</p>
                    </div>

                    <div className="border p-4" style={{'--bs-border-color':"#000"}}>
                        <Row className='border-bottom mb-2 pb-2'>
                            <Col xs="12" lg="3" md="4"><b className='font-gotham-rounded-bold'>NUTRIENTS</b></Col>
                            <Col xs="8" lg="3" md="4"><b className='font-gotham-rounded-bold'>Total Combined Amount</b></Col>
                            <Col xs="4" lg="1" md="1"><b className='font-gotham-rounded-bold'>OK</b></Col>
                            <Col xs="12" lg="5"><b className='font-gotham-rounded-bold'>Comments</b></Col>
                        </Row>
                        {productOverlapping.map(nutrient => {
                            if(nutrient.total_products > 1) {
                                return (
                                        <Row key={nutrient.id} className='mb-2'>
                                            <Col xs="12" lg="3" md="4">{nutrient.name}</Col>
                                            <Col xs="8" lg="3" md="2">{Number.isInteger(nutrient.total_found) ? nutrient.total_found : nutrient.total_found.toFixed(2)} {nutrient.unit}</Col>
                                            <Col xs="4" lg="1"><div className={`text-center ${styles.iconResult}`}>{isOverLimit(nutrient) === true ? <><BadNutrient/></> : <><OkNutrient/></> }</div></Col>
                                            <Col xs="12" lg="5">{isOverLimit(nutrient) === true && nutrient.limit_message }</Col>
                                        </Row>
                                    );
                            } else {
                                return ('')
                            }
                        })}
                    </div>
                    <div className='text-center my-4'>
                        <p>As with any supplement routine, consult your licensed healthcare professional to determine what is right for you.</p>
                    </div>
                </>
            }
            <Row className="pt-5">
                {props.products.map((_product,p_i) => (
                    <React.Fragment key={`product_selected_nutrients${p_i}`}>
                        <Col xs="12" md="6" lg="3" className=''>
                            <div className={`${styles.supplementWrapper}`}>
                                <div>
                                    <img className={`img-fluid d-block mx-auto ${styles.imgProductNutrients}`} src={_product.image} alt={_product.name}/>
                                </div>
                                <h5 className="text-uppercase text-center" style={{color:_product.color_title}}>{_product.name}</h5>
                            </div>
                            <div className="mt-3 mb-4">
                                {_product.supplement_facts_image !== null &&
                                    <img className='img-fluid d-block mx-auto' src={_product.supplement_facts_image} alt={`Supplement Facts ${_product.name}`}/>
                                }
                            </div>
                        </Col>
                    </React.Fragment>
                ))
                }
            </Row>
        </>
        }
        </>
    )
}

export default ProductNutrients;