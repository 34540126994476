import React, { useState, useEffect , useCallback , useMemo } from "react"
import Layout from "../components/services/layout"
import Seo from "../components/seo"
import ProductSelector from '../components/services/product_selector';
import ProductNutrients from '../components/services/product_nutrients';
import axios from "axios";
import _ from "lodash"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as styles from "../components/style/routine.module.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../utils/font-awesome';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const {GATSBY_BACKEND_URL} = process.env;

const Routine = () => {
  	const [categories, setCategories] = useState([]);
  	const [products, setProducts] = useState([]);
  	const [showRoutine, setShowRoutine] = useState(false);
  	const [showEmailForm, setShowEmailForm] = useState(false);

  	const getCategoriesData = useCallback(async () => {
		await axios.get(GATSBY_BACKEND_URL + "products/categories" , {})
				.then((res) => {
					setCategories(res.data);
				})
				.catch((error) => { 
					var result = {'request' : false};
					if (typeof error.response === 'object' && typeof error.response.data === 'object') {
						if(error.response.data.errors) {
							result.errors = _.flatten(_.toArray(error.response.data.errors));
						} else if (error.response.data.message) {
							result.errors = [error.response.data.message];
						}
					} else {
						result.errors = ['Something went wrong. Please try again.'];
					}
					setCategories(result);
				});
	},[])

	const updateProductsSelected = (items) => {
		setProducts(items);
  	}

	const updateShowRoutine = (value) => {
		setShowRoutine(value);
  	}

	/*const getUrlAllToCart = useMemo(() => {
		var url = "";
		if(products.length) {
			var string_products = "";
			products.forEach((item, i_index) => {
				string_products = string_products.concat(`id[]=${item.shopify_id}&quantity[]=1&`);
			})
			url = `https://www.healthycell.com/cart/add?${string_products}`;
		}
		return url;
	}, [products]);*/

	const changeMessageInVegan = useMemo(() => {
		if(products.length) {
			var selected_vegan = false;
			var selected_bioactive = false;

			products.forEach((item, i_index) => {
				if(item.name.toLowerCase() === "bioactive multi") {
					selected_bioactive = true;
				}
				if(item.name.toLowerCase() === "vegan essentials") {
					selected_vegan = true;
				}
			});

			if(selected_bioactive && selected_vegan) {
				return true;
			}
		}
		return false;
	}, [products]);

	useEffect( () => {
		getCategoriesData()
	}, [getCategoriesData]) // execute in the first render

  return (
    <Layout>
		<div className={`mb-5 ${styles.bgRoutine}`}>
			
		</div>
        <div className="container mx-auto">
            <div className="text-center">
                <h1 className="uppercase mb-3">YOUR PERSONALIZED HEALTHYCELL ROUTINE</h1>
                <h3 className="mb-3 font-gotham-rounded-medium">You can combine Healthycell products to help meet your goals.</h3>
                <p>Select the products you would like to combine to get a custom product routine.</p>
                <p className={`${styles.txtNoteHeader}`}>Note: You can combine multiple Healthycell products to meet your goals, however, we don't recommend taking Bioactive Multi and Vegan Essentials together since they are both daily multivitamins. Any nutrients that are found in more than one of the products you selected will appear in an Overlapping Nutrients list with an automatic calculation of the total amounts so you can evaluate your overall intake.</p>
            </div>
            <div>
              <ProductSelector onlyGiftItems={false} items={categories} multiselect={true} updateProductsSelected={updateProductsSelected} updateContinueAction={updateShowRoutine} />
            </div>

			{ showRoutine &&
				<>
					<div className="separator d-flex justify-content-center align-items-center my-3">
						<div className="line"></div>
						<div className="arrow d-flex justify-content-center align-items-center"><FontAwesomeIcon icon={'angle-down'} /></div>
					</div>
					<div className="px-0 px-lg-5 pt-5">
						<div className="text-center">
							<h2 className="uppercase mb-3">YOUR PERSONAL DAILY HEALTHYCELL ROUTINE</h2>
						</div>
						{products.length > 0 &&
							<>
							<div>
								{[...products].sort((a, b) => a.time_to_take_percent.start - b.time_to_take_percent.start).map((_product,p_i) => (
									<Row key={`product_selected_${p_i}`} className="mt-3 pb-3 align-items-center">
										<Col xs="12" md="3" lg="3" xl="3" className=''>
											<div>
												<img className={`img-fluid d-block mx-auto ${styles.imgProductLines}`} src={_product.image} alt={_product.name}/>
											</div>
											<p className="text-uppercase text-center" style={{color:_product.color_title}}>{_product.name}</p>
										</Col>
										<Col xs="12" md="6" lg="6" xl="7" className=''>
											{ _product.time_to_take_start_display &&  _product.time_to_take_end_display &&
												<>
												<div className="d-flex flex-nowrap flex-row justify-content-between routine-time-lines">
													<div style={{width: `${_product.time_to_take_percent.start}%` }}><div className="time-free"></div></div>
													<div style={{width: `${_product.time_to_take_percent.take}%` }}>
														<div className="time-take d-flex justify-content-between position-relative">
															<div className="align-items-center d-flex flex-column time-info start">
																<div>{_product.time_to_take_start_display}</div>
																<div className="dot-time bg-white rounded-circle border" style={{'--bs-border-color':'#000'}}></div>
															</div>
															<div className="align-items-center d-flex flex-column time-info end">
																<div>{_product.time_to_take_end_display}</div>
																<div className="dot-time bg-white rounded-circle border" style={{'--bs-border-color':'#000'}}></div>
															</div>
														</div>
													</div>
													<div style={{width: `${_product.time_to_take_percent.end}%` }}><div className="time-free"></div></div>
												</div>
												</>
											}
											
											<div className="text-white px-4 py-3 rounded-bottom font-gotham-rounded-book" style={{backgroundColor:_product.color_recommendation}}>
												{(_product.name.toLowerCase() === "vegan essentials" || _product.name.toLowerCase() === "bioactive multi" ) &&  changeMessageInVegan ? "You will not need Vegan Essentials if you take Bioactive Multi" : _product.description }
											</div>
										</Col>
										<Col xs="12" md="3" lg="3" xl="2" className='mt-3 mt-md-0'>
											{ _product.add_to_cart &&
												<a target="_blank" style={{'--bs-btn-border-radius':'2px'}} rel="noreferrer" className="btn btn-primary w-100 btn-lg font-gotham-rounded-medium" href={_product.add_to_cart}>Buy Now</a>
											}
										</Col>
									</Row>
								))
								}

								<div className="align-items-center d-flex flex-column flex-md-row justify-content-center my-5">
									<div>
										<button type="button" onClick={() => setShowEmailForm(true)} className="btn btn-lg btn-outline-primary font-gotham-rounded-medium" style={{'--bs-btn-border-radius':'2px'}}>Email my Routine</button>
									</div>
									{/*
									<div className="mx-3"><div className="align-items-center border d-flex justify-content-center rounded-circle" style={{'--bs-border-color':'#000','width':30,'height':30}}>&</div></div>
									<div>
										<a target="_blank" href={getUrlAllToCart} type="button" rel="noreferrer" className="btn btn-lg btn-primary font-gotham-rounded-medium" style={{'--bs-btn-border-radius':'2px'}}>Add All to Cart & Checkout</a>
									</div>
									*/}
								</div>
							</div>
							<div>
								<ProductNutrients products={products}/>
							</div>

							<div className="align-items-center d-flex flex-column flex-md-row justify-content-center mt-5">
								<div>
									<button type="button" onClick={() => setShowEmailForm(true)} className="btn btn-lg btn-outline-primary font-gotham-rounded-medium" style={{'--bs-btn-border-radius':'2px'}}>Email my Routine</button>
								</div>
								{/*<div className="mx-3"><div className="align-items-center border d-flex justify-content-center rounded-circle" style={{'--bs-border-color':'#000','width':30,'height':30}}>&</div></div>
								<div>
									<a target="_blank" href={getUrlAllToCart} type="button" rel="noreferrer" className="btn btn-lg btn-primary font-gotham-rounded-medium" style={{'--bs-btn-border-radius':'2px'}}>Add All to Cart & Checkout</a>
								</div>
								*/}
							</div>

							</>
						}
					</div>
				</>
			}
        </div>

		<SendRoutineModal
			show={showEmailForm}
			products={products}
			onHide={() => setShowEmailForm(false)}
		/>
    </Layout>
  )
}

export const Head = () => <Seo title="Routine" />

export default Routine

function SendRoutineModal(props) {
	const [emailRoutine, setEmailRoutine] = useState('');
	const [nameRoutine, setNameRoutine] = useState('');
	const [emailSending, setEmailSending] = useState(false);

	const [emailRoutineData, setEmailRoutineData] = useState([]);

	const sendEmail = useCallback(async () => {
		setEmailRoutineData([]);

		if(!emailRoutine.length || !nameRoutine.length ) {
			setEmailRoutineData({'errors' : ["Fields Required"]})
			return true;
		}

		setEmailSending(true);
		var list_products = [];
		props.products.forEach((item) => {
			list_products.push(item.id);
		})
		var request = {'email': emailRoutine,
						'name': nameRoutine,
						'products' : list_products };
		await axios.post(GATSBY_BACKEND_URL + "routine/send" , request)
				.then((res) => {
					var result = {'success':true};
					setEmailRoutineData(result);
					setTimeout(() => {
						props.onHide();
					}, 2000);
				})
				.catch((error) => { 
					var result = {'request' : false};
					if (typeof error.response === 'object' && typeof error.response.data === 'object') {
						if(error.response.data.errors) {
							result.errors = _.flatten(_.toArray(error.response.data.errors));
						} else if (error.response.data.message) {
							result.errors = [error.response.data.message];
						}
					} else {
						result.errors = ['Something went wrong. Please try again.'];
					}
					setEmailRoutineData(result);
				})
				.then(() => {
					setEmailSending(false);
				});
	},[emailRoutine , nameRoutine , props])

	useEffect( () => {
		setEmailRoutine('');
		setNameRoutine('');
		setEmailRoutineData([]);
	}, [props.show]) // execute in the first render

	return (
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
		<Modal.Header className='border-bottom-0' closeButton>
		</Modal.Header>
		<Modal.Body>
		  	<h2 className='text-center mb-2'><strong>Email my Routine</strong></h2>
			<Form.Group className="mb-3 shadow-sm max-size-input mx-auto">
				<Form.Control
					onChange={event => setNameRoutine(event.target.value)}
					placeholder="Please enter your name"
					aria-label="Email my routine - Name"
					type="email"
					value={nameRoutine}
				/>
			</Form.Group>
			<Form.Group className="mb-3 shadow-sm max-size-input mx-auto">
				<Form.Control
					onChange={event => setEmailRoutine(event.target.value)}
					placeholder="Please enter your email"
					aria-label="Email my routine - Email"
					type="email"
					value={emailRoutine}
				/>
			</Form.Group>
			{emailRoutineData.errors && emailRoutineData.errors.length &&
				<>
				<div className='alert alert-danger' role="alert">
					<ul className='mb-0'>
						{emailRoutineData.errors.map((error, error_i) => (
								<React.Fragment key={`error_key${error_i}`}>
									<li>{error}</li>
								</React.Fragment>
							))
						}
					</ul>
				</div>
				</>
			}
			<div className='text-center my-4'>
				<Button disabled={emailSending} className='rounded-0 px-5 font-gotham-rounded-medium' variant="primary" onClick={() => sendEmail()}>Send</Button>
			</div>
			{emailRoutineData.success && emailRoutineData.success === true &&
				<>
				<div className='alert alert-success' role="alert">
					Email sent.
				</div>
				</>
			}
			{emailSending &&
				<>
				<Row className='justify-content-center'>
					<div className="spinner-border" role="status">
						<span className="visually-hidden">Sending...</span>
					</div>
				</Row>
				</>
			}
		</Modal.Body>
	  	</Modal>
	);
}